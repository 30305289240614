import React from 'react';

import {Spacer} from '@nfq/react-grid';
import {m as motion} from 'framer-motion';
import Head from 'next/head';

import {H1} from 'UI/components/layout/Text';

import {OpacityAnimation} from 'UI/animations/shared';
import {Login as LoginLayout} from 'UI/layouts/login/Login';
import {LoginModule} from 'UI/modules/login/LoginModule';

import type {NextPageWithLayout} from 'types/global';

/**
 * Login Page.
 *
 * @returns The component.
 */
const Login: NextPageWithLayout = () => (
    <>
        <Head><title>o2 Brand Guardianship | Login</title></Head>
        <MotionH1 variants={OpacityAnimation} $isLight>Brand Guardianship</MotionH1>
        <Spacer y={10} isNotStretching />
        <LoginModule />
    </>
);

/**
 * Gets the layout for this page.
 *
 * @param router        The router object from nextjs.
 * @param pageProps     All page props given from server.
 * @param PageComponent The page component to render.
 *
 * @returns The complete page.
 */
Login.getLayout = (router, pageProps, PageComponent) => (
    <LoginLayout>
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        <PageComponent router={router} {...pageProps} />
    </LoginLayout>
);

/**
 * Gets the layout key for the used layout.
 *
 * @returns The complete page.
 */
Login.getLayoutKey = () => 'Login';

export default Login;

const MotionH1 = motion(H1);