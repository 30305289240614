import {Container, Spacer} from '@nfq/react-grid';
import {m as motion} from 'framer-motion';
import styled from 'styled-components';

import {H2, P} from 'UI/components/layout/Text';

import {ContentAnimation, HeaderTransition, HeadlineTransition, LogoTransition} from 'UI/animations/login';
import {OpacityAnimation} from 'UI/animations/shared';
import {LoginAside} from 'UI/layouts/login/LoginAside';

import {Logo} from 'Images/icons';
import {iconAsBackground} from 'UI/utils/helpers';

import type {WithChildren} from 'types/global';

/**
 * The `ComponentProps` interface defines the properties that can be passed to components for rendering.
 * It includes the `testId` property, which serves as a unique identifier for testing purposes, enabling developers to write reliable tests that interact with specific components.
 * This property is essential for ensuring that components can be accurately targeted during testing, facilitating better test coverage and maintainability.
 */
interface ComponentProps {
    /**
     * The `testId` property represents a unique identifier, usually in the form of a string, assigned to a component for testing purposes.
     * This property is crucial for uniquely identifying components during testing, allowing for more accurate and reliable tests.
     *
     * @default 'Login'
     */
    testId?: string;
}

/**
 * The `Login` component serves as the primary interface for users to access the login functionality of the application.
 * It contains branding elements, such as a logo and welcoming text, and is designed to be visually appealing while maintaining usability.
 * This component can also render child elements, allowing for flexibility in displaying login forms or additional information.
 *
 * @param props          The component props.
 * @param props.children The child elements that will be rendered within the Login component, typically including the login form.
 * @param props.testId   A unique identifier for the component, used primarily for testing purposes. This helps ensure that automated tests can accurately interact with the component.
 * @returns A JSX element representing the Login interface, which includes branding and child components for user interaction.
 *
 * @example
 * ```tsx
 * <Login>
 *     <LoginForm />
 * </Login>
 * ```
 */
const Login = ({children, testId = 'Login'}: WithChildren<ComponentProps>) => (
    <LoginWrapper data-cy={testId}>
        <LayoutWrap>
            <Content variants={ContentAnimation}>
                <Container as="section" isFluid>
                    <LogoWrapper>
                        <MotionLogo layoutId="Logo" transition={LogoTransition} />
                        <Spacer x={4} isInline isNotStretching />
                        <motion.div layoutId="LogoText" transition={LogoTransition}>
                            <P $isLight>Brand Guardianship</P>
                        </motion.div>
                    </LogoWrapper>
                    <Spacer y={8} isNotStretching />
                    <motion.div transition={HeadlineTransition}>
                        <MotionH2 variants={OpacityAnimation} $isLight layout>Willkommen zum</MotionH2>
                    </motion.div>
                    {children}
                </Container>
            </Content>
            <LoginAside />
        </LayoutWrap>
        <Wrapper layoutId="Header" transition={HeaderTransition} />
    </LoginWrapper>
);

Login.displayName = 'Login';
Login.defaultProps = {testId: 'Login'};

export {Login};

const LoginWrapper = styled.div`
    height: 100%;
    width: 100%;
`;

const Wrapper = styled(motion.div)`
    background-color: ${({theme}) => theme.colors.primary};
    height: 100vh;
    width: 100%;
`;

const LayoutWrap = styled.div`
    align-items: stretch;
    bottom: 0;
    display: flex;
    flex-wrap: nowrap;
    height: 100%;
    left: 0;
    overflow: hidden;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
`;

const Content = styled(motion.main)`
    display: flex;
    flex: 1 1 58%;
    flex-direction: column;
    justify-content: center;
`;

const LogoWrapper = styled.div`
    align-items: center;
    display: flex;
`;

const MotionLogo = styled(motion.div)`
    background-image: url(${iconAsBackground(Logo)});
    background-repeat: no-repeat;
    background-size: contain;
    height: 32px;
    width: 32px;
`;

const MotionH2 = motion(H2);