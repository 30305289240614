import {m as motion} from 'framer-motion';
import Image from 'next/image';
import styled from 'styled-components';

import {AsideAnimation} from 'UI/animations/login';

// eslint-disable-next-line import/extensions
import Logon from 'UI/assets/images/LoginAside.jpg';

/**
 * The LoginAside component displays a visually appealing aside section typically used in a login interface. It contains a styled image that
 * represents the login context, enhancing the user experience with its thoughtful design and responsive properties.
 * This component utilizes the AsideAnimation for animation effects and ensures that the image is loaded efficiently with a blur placeholder.
 * It also sets the image size dynamically based on the viewport width, optimizing its appearance on different screen sizes.
 *
 * @returns A JSX element representing the aside section with a login image.
 *
 * @example
 * ```tsx
 * <LoginAside />
 * ```
 */
const LoginAside = () => (
    <Aside variants={AsideAnimation}>
        <StyledImage
            alt="Login"
            placeholder="blur"
            sizes="50vw"
            src={Logon}
            fill
            priority
        />
    </Aside>
);

LoginAside.displayName = 'LoginAside';

export {LoginAside};

const Aside = styled(motion.aside)`
    flex: 0 0 42%;
    max-width: 1920px;
    position: relative;
`;

const StyledImage = styled(Image)`
    object-fit: cover;
    object-position: 75% 0%;
`;